import { ContactForm } from "../ContactForm";
import Clients from "./Clients";
import Features from "./Features";
import HomeCover from "./HomeCover";
import Offers from "./Offers";

export default function HomePageContent() {
  return (
    <div>
      <HomeCover />
      <Offers />
      <div id="features" className="bg-white text-black w-full">
        <Features />
      </div>
      <div id="clients">
        <Clients />
      </div>
      <div id="contact">
        <ContactForm />
      </div>
    
    </div>
  );
}
