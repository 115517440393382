import React from "react";
import { useTranslation } from "react-i18next";
import FeatureOne from "../../assets/images/onlineordering.png";
import FeatureTwo from "../../assets/images/pos.png";
import FeatureThree from "../../assets/images/orderlater.gif";
import VisaLogo from "../../assets/images/Visa New 2021.png";
import VippsLogo from "../../assets/images/vipps.png";
import MasterLogo from "../../assets/images/masterlogo.png";
import BankLogo from "../../assets/images/bankaxept.png";
import FeatureFive from "../../assets/images/sms.gif";

import { FlyInComponent } from "../../hook/ScrollEffect";
import Offers from "./Offers";

export default function Features() {
  const { t } = useTranslation();

  return (
    <div className="pt-4 my-32 md:px-16 py-32">
      {/* Heading with fly-in effect */}
      <FlyInComponent direction="y">
        <h1 className="uppercase text-5xl text-primary text-center font-bold py-32">
          {t("features")}
        </h1>
      </FlyInComponent>

      {/* Subheading with fly-in effect */}
      <FlyInComponent direction="y">
        <div className="py-16 mx-auto ps-12 md:px-32">
          <h2 className="mb-6 text-3xl">
            <span className="relative inline-block text-primary">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10"
              >
                <defs>
                  <pattern
                    id="ea469ae8-e6ec-4aca-8875-fc402da4d16e"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#ea469ae8-e6ec-4aca-8875-fc402da4d16e)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative font-bold">Tech</span>
            </span>{" "}
            {t("features-content")}
          </h2>
        </div>
      </FlyInComponent>

      <div>
        {/* Feature one: online ordering */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group relative">
           
            <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
              {t("feature-one")}
            </h3>
            <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
              {t("content-feature-one")}
            </p>
            <div className="lg:w-3/4 mx-auto">
              <img
                src={FeatureOne}
                alt={t("feature-one")}
                className=" w-full h-full object-cover"
              />
            </div>
          </div>
        </FlyInComponent>

        {/* Feature two: free up staff */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group lg:flex my-24">
            <div className=" mx-auto bg-gradient-secondary p-2 rounded-tr-full">
              <img
                src={FeatureTwo}
                alt={t("feature-two")}
                className=" w-full h-full object-cover"
              />
            </div>
            <div className="content-center">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-two")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4  mx-auto">
                {t("content-feature-two")}
              </p>
            </div>
          </div>
        </FlyInComponent>
        {/* Feature three: order later */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group my-24">
           
            <div className="content-center">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-three")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
                {t("content-feature-three")}
              </p>
              <div className="lg:w-1/2 mx-auto p-2">
              <img
                src={FeatureThree}
                alt={t("feature-three")}
                className=" w-full h-full object-cover"
              />
            </div>
            </div>
          </div>
        </FlyInComponent>
        {/* Feature four: payment */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group my-24">
           
            <div className="content-center">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-four")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
                {t("content-feature-four")}
              </p>
              <div className="flex flex-wrap mx-auto justify-center mb-10 lg:w-3/4">
              <img src={VippsLogo} alt="Vipps logo" className=" w-52 m-5" />
              <img src={VisaLogo} alt="Visa card logo" className=" w-32 m-5" />
              <img src={BankLogo} alt="bank axept logo" className=" w-32 m-5" />
              <img
                src={MasterLogo}
                alt="Master card logo"
                className=" w-32 m-5"
              />
            </div>
            </div>
          </div>
        </FlyInComponent>
        <Offers />

         {/* Feature five: sms */}

         <FlyInComponent direction="y">
          <div className=" mx-auto text-center group lg:flex my-24">
          
            <div className="content-center flex-1">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-five")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4  mx-auto">
                {t("content-feature-five")}
              </p>
            </div>
            <div className=" mx-auto flex-1">
              <img
                src={FeatureFive}
                alt={t("feature-five")}
                className=" w-full h-full object-cover"
              />
            </div>
          </div>
        </FlyInComponent>
      </div>
    </div>
  );
}
