import { Link } from "react-router-dom";
import "./index.css";
import LanguagesSelector from "../Languages";
import Logo from "../Logo";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="sticky top-0 bg-black z-10">
      <div className="py-5 mx-auto lg:w-3/4">
        <div className="relative flex items-center justify-between">
          <Link
            to="/"
            aria-label={t("navbar.title")} // Using translation for aria-label
            title={t("navbar.title")} // Using translation for title
            className="inline-flex items-center"
          >
            <Logo />
          </Link>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            {/* Home - scroll to the top */}
            <Link
              to="/"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
            >
              {t("home")}
            </Link>

            {/* Scroll to Features section */}
            <Link
              to="/"
              onClick={() =>
                document
                  .getElementById("features")
                  .scrollIntoView({ behavior: "smooth" })
              }
              className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
            >
              {t("features")}
            </Link>

            {/* Scroll to Clients section */}
            <Link
              to="/"
              onClick={() =>
                document
                  .getElementById("clients")
                  .scrollIntoView({ behavior: "smooth" })
              }
              className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
            >
              {t("clients")}
            </Link>

            {/* Scroll to Contact Us section */}
            <Link
              to="/"
              onClick={() =>
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth" })
              }
              className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
            >
              {t("contact-us")}
            </Link>
          </ul>

          <ul className="flex items-center hidden space-x-8 lg:flex">
            <Link to="#">
              <LanguagesSelector />
            </Link>
          </ul>
          <div className="lg:hidden">
            <button
              aria-label={t("navbar.openMenu")} // Translation for aria-label
              title={t("navbar.openMenu")} // Translation for title
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 right-0 w-full">
                <div className="p-5 bg-black border rounded shadow-sm text-white">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <Link
                        to="/"
                        aria-label={t("navbar.title")} // Translation for aria-label
                        title={t("navbar.title")} // Translation for title
                        className="inline-flex items-center"
                      >
                        <Logo />
                      </Link>
                    </div>
                    <div>
                      <button
                        aria-label={t("navbar.closeMenu")} // Translation for aria-label
                        title={t("navbar.closeMenu")} // Translation for title
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4 flex flex-col">
                      {/* Home - scroll to the top */}
                      <Link
                        to="/"
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                        className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
                      >
                        {t("home")}
                      </Link>

                      {/* Scroll to Features section */}
                      <Link
                        to="/"
                        onClick={() =>
                          document
                            .getElementById("features")
                            .scrollIntoView({ behavior: "smooth" })
                        }
                        className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
                      >
                        {t("features")}
                      </Link>

                      {/* Scroll to Clients section */}
                      <Link
                        to="/"
                        onClick={() =>
                          document
                            .getElementById("clients")
                            .scrollIntoView({ behavior: "smooth" })
                        }
                        className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
                      >
                        {t("clients")}
                      </Link>

                      {/* Scroll to Contact Us section */}
                      <Link
                        to="/"
                        onClick={() =>
                          document
                            .getElementById("contact")
                            .scrollIntoView({ behavior: "smooth" })
                        }
                        className="font-medium tracking-wide transition-colors duration-200 hover:text-primary"
                      >
                        {t("contact-us")}
                      </Link>

                      {/* Languages Selector - No scrolling */}
                      <Link to="#">
                        <LanguagesSelector />
                      </Link>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
