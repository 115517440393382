import React from "react";
import { useTranslation } from "react-i18next";

import { FlyInComponent } from "../../hook/ScrollEffect";
import { PrimaryButton } from "../Buttons";

export default function Offers() {
  const { t } = useTranslation();

  return (
    <div className="max-w-5xl my-28 md:mx-auto sm:text-center md:mb-12">
      {/* Heading with fly-in effect */}
      {/* <FlyInComponent direction="y">
        <h1 className="uppercase text-5xl text-primary text-center font-bold mt-64 mb-32">
          {t("we-offer")}
        </h1>
      </FlyInComponent> */}

      {/* Features grid */}
      <div className="grid gap-12 row-gap-12 lg:grid-cols-2">
        <FlyInComponent direction="y">
          <div className="m-6 text-center bg-white border-4 border-primary rounded-tr-full rounded-tl-full rounded-br-full p-6 h-full flex flex-col">
            <h2 className="uppercase font-bold text-3xl my-4 text-darkGreen">
              <span className=" text-5xl text-primary">zero</span> upfront
              cost
            </h2>
            <div className="text-center w-3/4 mx-auto">
              <p className="text-xl text-black"> {t("no-initial-fee")}</p>
              <p className="text-xl text-black">{t("free-trial")}</p>
            </div>
          </div>
        </FlyInComponent>

        <FlyInComponent direction="y">
        <div className="m-6 text-center bg-white border-4 border-primary rounded-tr-full rounded-tl-full rounded-br-full p-6 h-full flex flex-col">
        <h2 className="uppercase font-bold text-3xl my-4 text-darkGreen">
              <span className=" text-5xl  text-primary">free</span> website
            </h2>
            <div className="text-start w-3/4 mx-auto">
              <p className="text-xl text-black">{t("online-visibility")}</p>
              <p className="text-xl text-black">{t("seo")}</p>
              <p className="text-xl text-black">
                {t("integrate-order-system")}
              </p>
            </div>
          </div>
        </FlyInComponent>
      </div>
      <FlyInComponent direction="y">
        <div className="text-2xl uppercase my-20 text-center">
          <p className="mb-6">Fast Support!</p>
          <p className="mb-16"> Tailored Solutions!</p>
          <PrimaryButton
          label={t("contact-us-now")}
          onClick={() =>
            document
              .getElementById("contact")
              .scrollIntoView({ behavior: "smooth" })
          }
        />
        </div>
       
      </FlyInComponent>
    </div>
  );
}
