import Image from "../../assets/images/coverimage.png";

import { PrimaryButton } from "../Buttons";
import { useTranslation } from "react-i18next";
import { FlyInComponent } from "../../hook/ScrollEffect";

export default function HomeCover() {
  const { t } = useTranslation();

  return (
    <FlyInComponent direction="y">
      <div className="flex flex-col-reverse md:flex-row justify-center content-end mt-10">
        {/* Left Side: Text and Button */}

        <div className="flex flex-col text-center md:text-start content-end justify-center md:flex-1">
          <div className="sm:text-xl md:text-2xl lg:text-3xl md:w-3/4 lg:w-1/2 mx-auto">
            <p className="my-10">{t("cover-content")} </p>
            <div>
              <PrimaryButton
                label={t("book-demo")}
                onClick={() =>
                  document
                    .getElementById("contact")
                    .scrollIntoView({ behavior: "smooth" })
                }
              />
            </div>
          </div>
        </div>

        {/* Right Side: Image Carousel */}
        <div className="md:flex-1 w-full mx-auto bg-gradient-primary border-4 border-white rounded-tr-full rounded-tl-full rounded-br-full p-16">
          <img
            src={Image}
            alt="pos laptop mobile order system images"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </FlyInComponent>
  );
}
